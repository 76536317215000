import { CookieConsentData, Participant, UserOnlineStatus, UserPlan } from "@/types";

import AI from "/public/assets/fileTypes/ai.png";
import GIF from "/public/assets/fileTypes/gif.png";
import JPG from "/public/assets/fileTypes/jpg.png";
import PNG from "/public/assets/fileTypes/png.png";
import TIFF from "/public/assets/fileTypes/tiff.png";
import XLS from "/public/assets/fileTypes/xls.png";
import ZIP from "/public/assets/fileTypes/zip.png";
import PDF from "/public/assets/fileTypes/pdf.png";

export const OAUTH_GOOGLE_ENDPOINT = "https://accounts.google.com/o/oauth2/v2/auth";
export const OAUTH_APPLE_ENDPOINT = "https://appleid.apple.com/auth/authorize";

export const GOOGLE_APP_STORE_LINK =
	"https://play.google.com/store/apps/details?id=com.doughnutlabs.techxapp&pcampaignid=web_share";
export const APPLE_APP_STORE_LINK = "https://apps.apple.com/app/techxeed/id6474541647";

export const LOCAL_INBOX_PAGE_KEY = "local-inbox-page";
export const LOCAL_ADMIN_INBOX_PAGE_KEY = "local-admin-inbox-page";
export const LOCAL_GDPR_CONSENT = "local-gdpr-consent";
export const LOCAL_AFFILIATE_REF_KEY = "local-affiliate-ref";
export const LOCAL_CHAT_NOTICE_KEY = "local-chat-notice";

export const SEE_HOW_IT_WORKS =
	"https://techxeed-prod-user-data.s3.eu-north-1.amazonaws.com/local_vids/See_How_Techxeed_Works.mp4";

export const Currency = "$";
export const Discount = 20;

export const progressList = [
	{ value: "NEW", lable: "New" },
	{ value: "IN_PROGRESS", lable: "In Progress" },
	{ value: "COMPLETED", lable: "Completed" },
	{ value: "IN_REVIEW", lable: "In Review" },
	{ value: "LATE", lable: "Late" },
	{ value: "CANCELLED", lable: "Cancelled" },
];

export const roles = [
	{ value: "ADMIN", lable: "Admin" },
	{ value: "CUSTOMER", lable: "Customer" },
	{ value: "DIRECTOR", lable: "Director" },
];

export const supportedTypes = [
	"image/jpeg",
	"image/png",
	"image/gif",
	"image/svg+xml", // Added for SVG images
	"image/webp", // Added for WebP images
	"image/bmp", // Added for BMP images
	"image/tiff", // Added for TIFF images
	"image/vnd.microsoft.icon", // Added for ICO files
	// Video MIME Types
	"video/mp4",
	"video/webm",
	"video/ogg",
	"video/mpeg", // Added for MPEG videos
	"video/3gpp", // Added for 3GPP videos, used on mobile devices
	"video/3gpp2", // Added for 3GPP2 videos
	"video/x-msvideo", // Added for AVI videos
	"video/x-ms-wmv", // Added for Windows Media Video (WMV) files
	"video/quicktime", // Added for QuickTime (MOV) videos
];

export const getImageType = (type: string) => {
	switch (type) {
		case "jpg":
		case "jpeg":
			return JPG;
		case "png":
			return PNG;
		case "pdf":
			return PDF;
		case "ai":
			return AI;
		case "zip":
			return ZIP;
		case "xls":
			return XLS;
		case "tiff":
			return TIFF;
		case "gif":
			return GIF;
		default:
			return JPG;
	}
};

export const DELETED_PARTICIPANT: Participant = {
	_id: "0x1",
	firstName: "Deleted",
	lastName: "User",
	onlineStatus: UserOnlineStatus.OFFLINE,
	email: "deleted@techxeed.com",
	plan: { duration: UserPlan.free, planID: "" },
	role: "CUSTOMER",
	bio: "",
};

export const DEFAULT_COOKIE_CONSENT: CookieConsentData = {
	required: true,
	performance: true,
	advertisements: true,
};
