"use client";

import Link from "next/link";

import { useToast } from "@/components/ui/use-toast";

export type Props = { href: string; label: string; requiresAuth?: boolean; isSignedIn?: boolean };

export default function FooterLink({ href, label, requiresAuth, isSignedIn }: Props) {
	const { toast } = useToast();

	if (!isSignedIn && requiresAuth) {
		return (
			<button
				className="text-start"
				onClick={() => toast({ title: "Please login or sign-up to access this page" })}
			>
				{label}
			</button>
		);
	}

	return <Link href={href}>{label} </Link>;
}
