"use client";

import { PlayIcon } from "lucide-react";
import { MouseEventHandler } from "react";

import { cn } from "@/lib/utils";
import { SEE_HOW_IT_WORKS } from "@/lib/constant";
import { useDelayedToggleState } from "@/lib/hooks";

import { VideoPlayer } from "@/components/cards/MediaCard";

type Props = {
	buttonClassName?: string;
	buttonIconClassName?: string;
	buttonIconBgClassName?: string;
	buttonLabelClassName?: string;
};
export default function HowTechxeedWorks({
	buttonClassName,
	buttonIconBgClassName,
	buttonIconClassName,
	buttonLabelClassName,
}: Props) {
	const [isBigPicture, showBigPicture, setBigPictureVisibility] = useDelayedToggleState(false);

	const startBigPicture: MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
		if (
			isBigPicture &&
			e.currentTarget !== e.target &&
			(e.target as Element).parentElement !== e.currentTarget
		) {
			return;
		}

		const show = !isBigPicture;
		const root = document.body;
		root.style.overflow = show ? "hidden" : "auto";
		root.style.paddingRight = show ? "0.25rem" : "0";

		setBigPictureVisibility(show);
	};

	return (
		<>
			<button
				onClick={startBigPicture}
				className={cn("inline-flex items-center gap-2", buttonClassName)}
			>
				<div
					className={cn(
						"inline-flex size-6 items-center justify-center rounded-full bg-tertiary text-background md:size-8",
						buttonIconBgClassName,
					)}
				>
					<PlayIcon
						className={cn(
							"ml-0.5 size-4 fill-background md:size-[1.125rem]",
							buttonIconClassName,
						)}
					/>
				</div>
				<label className={cn("pointer-events-none sm:text-sm2", buttonLabelClassName)}>
					How <span className="text-secondary">Techxeed</span> works
				</label>
			</button>
			{isBigPicture && (
				<div
					onClick={startBigPicture}
					className={cn(
						"transition-all duration-500 flex flex-col items-center justify-center",
						showBigPicture && "fixed inset-0 bg-black/60 z-50",
					)}
				>
					<div className="relative grid grid-rows-[auto_2rem] md:h-[calc(100lvh-6rem)]">
						<VideoPlayer
							url={SEE_HOW_IT_WORKS}
							className=""
							childClassName=""
							thumbnail=""
						/>
					</div>
				</div>
			)}
		</>
	);
}
