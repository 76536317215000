import Link from "next/link";
import Image, { type StaticImageData } from "next/image";
import { type VariantProps, cva } from "class-variance-authority";

import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

const containerVariant = cva("group/app_container grid", {
	variants: {
		intent: {
			accent: "bg-[#131313] hover:bg-[#131313] hover:text-secondary",
			secondary: "bg-secondary hover:bg-secondary",
		},
		size: {
			xs: "h-12 grid-cols-[1.25rem_0.25rem_1fr] gap-1.5 text-[0.6rem]",
			sm: "h-14 grid-cols-[1.75rem_0.5rem_1fr] gap-4 text-xsm xs:gap-2 xs:text-xs md:gap-3 xl:gap-2 2xl:text-sm",
			lg: "h-16 grid-cols-[2.5rem_0.5rem_1fr] gap-4 text-sm sm:text-base",
		},
	},
	defaultVariants: {
		intent: "accent",
		size: "sm",
	},
});

const iconVariant = cva("", {
	variants: {
		size: {
			xs: "size-5",
			sm: "size-7",
			lg: "size-8 pl-2",
		},
	},
	defaultVariants: {
		size: "sm",
	},
});

const separatorVariant = cva("", {
	variants: {
		intent: {
			accent: "bg-secondary",
			secondary: "bg-secondary-foreground",
		},
		size: { xs: "h-5", sm: "h-6", lg: "h-8" },
	},
	defaultVariants: {
		intent: "secondary",
		size: "sm",
	},
});

const spanVariants = cva("leading-tight transition-colors", {
	variants: {
		intent: {
			accent: "text-muted-foreground group-hover/app_container:text-secondary/75",
			secondary: "text-muted",
		},
		size: {
			xs: "text-[0.55rem]",
			sm: "text-xsm xs:text-xs",
			lg: "text-xsm2",
		},
	},
	defaultVariants: {
		intent: "accent",
		size: "sm",
	},
});

type Props = VariantProps<typeof containerVariant> & {
	store: string;
	icon: StaticImageData;
	href: string;
	className?: string;
};

export default function AppContainer({ store, icon, href, intent, size, className }: Props) {
	return (
		<Button
			asChild
			aria-label={`Go to ${store}`}
			className={cn(containerVariant({ intent, size }), className)}
		>
			<Link target="_blank" href={href}>
				<Image
					src={icon}
					sizes="10vw"
					quality={100}
					alt=""
					aria-hidden
					className={iconVariant({ size })}
				/>
				<Separator orientation="vertical" className={separatorVariant({ intent, size })} />
				<label className="pointer-events-none leading-tight">
					<span className={spanVariants({ intent, size })}>Get it on</span>
					<br />
					{store}
				</label>
			</Link>
		</Button>
	);
}
